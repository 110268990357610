<template>
    <div class="container">
        <div class="row">
            <LightGallery
                    :images="images"
                    :index="index"
                    :disable-scroll="true"
                    @close="index = null"
            />
        </div>

        <splide :options="splideOptions">
            <splide-slide v-for="(image, imageIndex) in images" :key="image.url">
                <img class="card-img-top carousel-image" :src="image.url" @click="index = imageIndex">
            </splide-slide>
        </splide>

    </div>

</template>
<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
    export default {
        name: "recent_project_carousel",
        props: ['cardOptions'], // {cardWidth: '300px', cardHeight: '300px'}
        components: {
            Splide,
            SplideSlide,
        },
        methods: {
            onSwiper(swiper) {
                console.log(swiper);
            },
            onSlideChange() {
                console.log('slide change');
            },
        },
        data() {
            return {
                splideOptions: {
                    type      : 'loop',
                    perPage   : 3,
                    perMove   : 1,
                    gap       : '1rem',
                    pagination: false,
                    fixedHeight: this.cardOptions.cardHeight,
                    fixedWidth: this.cardOptions.cardWidth
                },
                images: [
                    {title: '', url: './static/recent_projects/IMG_0856.jpg'},
                    {title: '', url: './static/recent_projects/IMG_0857.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134914.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134917.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134925.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134928.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134933.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134939.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134943.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134951.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134956.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_134958.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_135001.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210424_135008.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_084416.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_084452.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085422.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085425.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085429.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085434.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085437.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085441.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085553.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085556.jpg'},
                    {title: '', url: './static/recent_projects/IMG_20210515_085559.jpg'}
                ],
                index: null
            }
        }
    }
</script>

<style>
    .carousel-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .splide__arrow--next {
        right: -3rem;
    }

    .splide__arrow--prev {
        left: -3rem;
    }
    .splide__arrow svg {
        fill: #007bff;
    }
</style>