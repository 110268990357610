<template>
  <div class="container">
    <div class="row mt-5">
        <div class="col text-center">
            <h2>Contact Us</h2>
            <hr class="small-hr-line">
        </div>
    </div>
    <div class="row">
        We proudly offer services for customers throughout Western Washington.
    </div>
    
    <div class="row top-buffer">
        <div class="col-sm">
          <h4>Mailing Address:</h4>
          <div>P.O. Box 1593</div>
          <div>Mukilteo, WA 98275</div>
        </div>
        <div class="col-sm">
          <h4>Physical Address:</h4>
          <div>4617 Chennault Beach Road</div>
          <div>Mukilteo, WA 98275</div>
        </div>
        
        <div class="col-sm">
          <div><h4 class="inline-header">Phone:</h4><span>(425) 315-8505</span></div>
          <div><h4 class="inline-header">Fax:</h4><span>(425) 315-0746</span></div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm">
          <h4>Hours of Operation:</h4>
          <div>Monday - Friday</div>
          <div>7am - 4pm</div>
        </div>
    </div>


    <div class="row top-buffer">
        Email your ideas, drawings, step files, or dxf's to sales@danddwelding.com
    </div>

    <div class="row top-buffer mb-5">
        <!-- Embedded Google Maps -->
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.052178389808!2d-122.29829068468824!3d47.89667537588803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549003f6eff00625%3A0x88a88a8e066036b3!2sD%20%26%20D%20Welding!5e0!3m2!1sen!2sus!4v1611524363190!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact"
}
</script>

<style scoped>

</style>