<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h2>Equipment & Capabilities</h2>
                <hr class="small-hr-line">
            </div>
        </div>

        <div class="row">
            <p class="larger-p">We provide a wide arrange of capabilities.</p>
        </div>
        <div class="row mt-5">
            <div class="col text-center">
                <h4>Services</h4>
                <hr class="small-hr-line">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ul class="custom-ul">
                    <li>Welding – Fabrication - Machining</li>
                    <li>Laser Cutting </li>
                    <li>Tube / Pipe Bending</li>
                    <li>Sheetmetal</li>
                    <li>Production</li>
                    <li>Prototyping</li>
                    <li>In-House CWI QC1Weld Inspection</li>
                </ul>
            </div>
            <div class="col">
                <ul class="custom-ul">
                    <li>CAD/CAM software capabilities</li>
                    <li>Turnkey Assembly –Start to finish</li>
                    <li>ValueEngineering</li>
                    <li>Inspection</li>
                    <li>Deburring</li>
                    <li>Sandblasting</li>
                </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col text-center">
                <h4>Equipment</h4>
                <hr class="small-hr-line">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ul class="custom-ul">
                    <li>
                        Two 3-Axis CNC Vertical Mills
                        <ul class="custom-ul">
                            <li>Hyundai F650 –55x26</li>
                            <li>Haas VF 5–50x25</li>
                        </ul>
                    </li>
                    <li>
                        2-Axis Mill
                        <ul class="custom-ul">
                            <li>Protrak –12x28</li>
                        </ul>
                    </li>
                    <li>Bender –Tubes up to 3” and Pipes up to 2” SCH80</li>
                </ul>
            </div>
            <div class="col">
                <ul class="custom-ul">
                    <li>
                        Fiber Laser Cutter
                        <ul class="custom-ul">
                            <li>3kW</li>
                            <li>5x10 dual table</li>
                            <li>Capable of cutting up to 3/8” aluminum & stainless and 3/4” steel</li>
                        </ul>
                    </li>
                    <li>
                        Press Brake
                        <ul class="custom-ul">
                            <li>12’, 192ton</li>
                            <li>CNC Programmable</li>
                        </ul>
                    </li>
                    <li>
                        Production Bandsaw
                        <ul class="custom-ul">
                            <li>Tolerance capabilities up to ±0.005</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <img width="320" src="../assets/equipment/fibermak_laser.png" alt="laser">
            </div>
            <div class="col">
                <img width="320" src="../assets/equipment/cnc.png" alt="cnc">
            </div>
            <div class="col">
                <img width="320" src="../assets/equipment/press_brake.png" alt="press brake">
            </div>
        </div>


        <div class="row mt-5">
            <div class="col text-center">
                <h4>Welding</h4>
                <hr class="small-hr-line">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ul class="custom-ul">
                    <li>WGTAW (TIG)</li>
                    <li>GMAW (MIG)</li>
                    <li>FCAW</li>
                    <li>SMAW</li>
                </ul>
            </div>
            <div class="col">
                <ul class="custom-ul">
                    <li>AWS D1.1</li>
                    <li>AWS D1.2</li>
                    <li>AWS D1.6</li>
                    <li>AWS D14.1</li>
                    <li>AWS D17.1</li>
                </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col text-center">
                <h4>Materials</h4>
                <hr class="small-hr-line">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ul class="custom-ul">
                    <li>Aluminum</li>
                    <li>Stainless stee</li>
                    <li>Steel</li>
                    <li>Copper</li>
                    <li>Brass</li>
                </ul>
            </div>
            <div class="col">
                <ul class="custom-ul">
                    <li>Bronze</li>
                    <li>Titanium</li>
                    <li>Inconel</li>
                    <li>Plastics</li>
                    <li>Exotic Metals</li>
                </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col text-center">
                <h4>Certifications</h4>
                <hr class="small-hr-line">
            </div>
        </div>
<!--        TODO: If should we bother with this section?-->
<!--        <div class="row">-->
<!--            <div class="col offset-xl-2">-->
<!--                <div class="card border-0" style="width: 15rem;">-->
<!--                    <img src="../assets/cert_logos/aws_logo.jpg" class="card-img-top" alt="aws logo">-->
<!--                    <div class="card-body">-->
<!--                        <h5 class="card-title">American Welding Society</h5>-->
<!--                        <p class="card-text">Some text about AWS</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col">-->
<!--                <div class="card border-0" style="width: 15rem;">-->
<!--                    <img src="../assets/cert_logos/wabo_logo.jpg" class="card-img-top" alt="aws logo">-->
<!--                    <div class="card-body">-->
<!--                        <h5 class="card-title">Washington Association of Building Officials</h5>-->
<!--                        <p class="card-text">Some text about WABO</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row mb-5">
            <div class="col w-100">
                <ul class="custom-ul m-auto d-table">
                    <li>AWS QC-1 American Welding Society Certified Welding Inspector</li>
                    <li>AWS D1.1: Process GTAW, GMAW, FCAW</li>
                    <li>AWS D1.3: Process GTAW, GMAW, FCAW</li>
                    <li>AWS D1.2: Process GTAW, GMAW</li>
                    <li>AWS D1.6 Process: GTAW, GMAW</li>
                    <li>AWS D14.1:Process GTAW, GMAW, FCAW</li>
                    <li>AWS D17.1: Process GTAW</li>
                    <li>AS9100/ISO 9001 Compliant</li>
                    <li>WABO Certified</li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col">
                <img width="250" src="../assets/cert_logos/aws_logo.jpg" alt="aws logo">
            </div>
            <div class="col">
                <img width="250" src="../assets/cert_logos/wabo_logo.jpg" alt="wabo logo">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "capabilities"
    }
</script>

<style scoped>
 .background-image {
     background-image: url("/public/static/");
 }
</style>