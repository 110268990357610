<template>
  <div id="app">
    <header>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div class="container">
          <router-link class="nav-link" to="/">
            <img class="nav-logo" src="./assets/dnd_logo_custom.png" height="36">
            <a class="navbar-brand">Welding Fabrication</a>
          </router-link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/projects">Projects</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/capabilities">Capabilities</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/careers">Careers</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <div class="app main-content">
      <!-- Specific page body will be put here -->
      <router-view class="router-body"></router-view>
    </div>

    <footer class="py-4 bg-dark text-white">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <div class="footer-header">Physical  Address</div>
            <p>4617 Chennault Beach Road Mukilteo, WA 98275</p>
          </div>
          <div class="col text-center">
            <div class="footer-header">Mailing Address</div>
            <p>P.O. Box 1593 Mukilteo, WA 98275</p>
          </div>
          <div class="col text-center">
            <div class="footer-header">Email</div>
            <p>sales@danddwelding.com</p>
          </div>
          <div class="col text-center">
            <div class="footer-header">Contact</div>
            <div>Phone: (425) 315-8505</div>
            <div>Fax: (425) 315-0746</div>
          </div>
        </div>
        <p class="m-0 text-center">Copyright &copy; D&D Welding 2021</p>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    methods: {
      getActiveClass: () => {
        return 'active'
      }
    }
  }
</script>

<style>
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  min-height: 100vh;
}

.main-content {
  padding-bottom: 185px; /* Footer height + a bit for whitespace */
}

.small-hr-line {
  width: 6%;
  margin-top: -5px;
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 165px;
}

.footer-header {
  font-weight: bold;
  font-size: 1.1em;
}

.router-body {
  padding-top: 56px;
}
.nav-logo {
  padding-right: 5px;
}
.top-buffer {
  margin-top:20px;
}
.inline-header {
  display:inline
}
 /* Custom Card Text */
.card-img-caption {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}
.card-img-caption .card-img-top {
  z-index: 0;
}
.card-img-caption .card-text {
  font-weight: bold;
  text-align: center;
  width: 100%;
  top: 70%;
  position: absolute;
  z-index: 1;
  background-color: rgba(38, 38, 38, 0.78);
  color: #f5f5f5;
}

.card-img-caption:hover {
  cursor: pointer;
}

.card-img-caption:hover .card-text {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  color: #2c3e50;
}

.card-text:hover {
}

.industry-card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

/* Image gallery grid*/
.gallery-item {
  float:left;
  width: 250px;
  height: 250px;
  object-fit: contain;
  padding-bottom: 10%; /* = width for a 1:1 aspect ratio */
  margin:1.66%;
  margin-right: 20px;
}

.gallery-item-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* or object-fit: contain; */
}

.gallery-item-img:hover {
  filter: brightness(125%);
  cursor: pointer;
}

/* Global change of li icons */
ul.custom-ul {
  list-style-type: none;
}
ul.custom-ul li:before {
  content: '\f061 ';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900; /* Fix version 5.0.9 */
  margin:0 5px 0 -15px;
  color: #1abbff;
}

/* Add these so a spinner stops spnning for light gallery and so that the light gallery overlay is in front of everything*/
.light-gallery__spinner {
  display: none !important;
}

.light-gallery__modal {
  z-index: 2000 !important;
}

</style>
