<template>
    <div class="container">
    <div class="row mt-5">
        <div class="col text-center">
            <h2>Careers</h2>
            <hr class="small-hr-line">
        </div>
    </div>
        <p>At D&D Welding, we know that the most important part of a company is its people.
            We are a family-owned and family oriented company that takes pride in our work and our people.
            We aim to provide all of our employees the opportunity for long-term growth as well as the ability to make an impact.
            If you want to join a growing team that pushes the envelope and lives out our values, please come join us!</p>
        <p>We are always looking for experienced welders and machinists.</p>
        <p> Start a conversation today by emailing your resume to office@danddwelding.com.</p>
    </div>

</template>

<script>
    export default {
        name: "careers"
    }
</script>

<style scoped>

</style>