<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h2>Custom</h2>
                <hr class="small-hr-line">
            </div>
        </div>
        <div class="row">
            <LightGallery
                    :images="images"
                    :index="index"
                    :disable-scroll="true"
                    @close="index = null"
            />

            <div
                    v-for="(image, imageIndex) in images"
                    :key="image.url"
                    @click="index = imageIndex"
            >
                <div class="gallery-item">
                    <img class="gallery-item-img" :src="image.url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "custom_work_component",
        beforeMount() {
        },
        data() {
            return {
                images: [
                    {title: '', url: '../static/custom/cos-bracket-1.jpg'},
                    {title: '', url: '../static/custom/cos-bracket-2.jpg'},
                    {title: '', url: '../static/custom/cos-fireplace-1.jpg'},
                    {title: '', url: '../static/custom/cos-fireplace-2.jpg'},
                    {title: '', url: '../static/custom/cos-fireplace-3.jpg'},
                    {title: '', url: '../static/custom/costom-1.jpg'},
                    {title: '', url: '../static/custom/costom-2.jpg'},
                    {title: '', url: '../static/custom/cust-artdesigns-1.jpg'},
                    {title: '', url: '../static/custom/cust-artdesigns-2.jpg'},
                    {title: '', url: '../static/custom/cust-artdesigns-3.jpg'},
                    {title: '', url: '../static/custom/cust-bus-sign-1.jpg'},
                    {title: '', url: '../static/custom/cust-bus-sign-2.jpg'},
                    {title: '', url: '../static/custom/cust-coffee-1.jpg'},
                    {title: '', url: '../static/custom/cust-coffee-2.jpg'},
                    {title: '', url: '../static/custom/cust-coffee-3.jpg'},
                    {title: '', url: '../static/custom/cust-fireplace-1.jpg'},
                    {title: '', url: '../static/custom/cust-fireplace-2.jpg'},
                    {title: '', url: '../static/custom/cust-fireplace-3.jpg'},
                    {title: '', url: '../static/custom/cust-fireplace-4.jpg'},
                    {title: '', url: '../static/custom/cust-nailsalon-1.jpg'},
                    {title: '', url: '../static/custom/cust-powder-sofa-1.jpg'},
                    {title: '', url: '../static/custom/cust-powder-sofa-2.jpg'},
                    {title: '', url: '../static/custom/cust-powder-sofa-3.jpg'},
                    {title: '', url: '../static/custom/cust-sofa-1.jpg'},
                    {title: '', url: '../static/custom/cust-stagecoach-1.jpg'},
                    {title: '', url: '../static/custom/cust-stagecoach-2.jpg'},
                    {title: '', url: '../static/custom/cust-vent-1.jpg'},
                    {title: '', url: '../static/custom/cust-wine-rack-1.jpg'},
                    {title: '', url: '../static/custom/cut-wine-rack-2.jpg'},
                ],
                index: null
            }
        }
    }
</script>

<style scoped>

</style>