<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h2>Aerospace</h2>
                <hr class="small-hr-line">
            </div>
        </div>
        <div class="row">
            <LightGallery
                    :images="images"
                    :index="index"
                    :disable-scroll="true"
                    @close="index = null"
            />

            <div
                    v-for="(image, imageIndex) in images"
                    :key="image.url"
                    @click="index = imageIndex"
            >
                <div class="gallery-item">
                    <img class="gallery-item-img" :src="image.url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aero_component",
        beforeMount() {
        },
        data() {
            return {
                images: [
                    {title: '', url: '../static/aero/aero-cylinders-1.jpg'},
                    {title: '', url: '../static/aero/aero-cylinders-2.jpg'},
                    {title: '', url: '../static/aero/aero-heli-recti-1.jpg'},
                    {title: '', url: '../static/aero/aero-heli-recti-2.jpg'},
                    {title: '', url: '../static/aero/aero-hose-bib-1.jpg'},
                    {title: '', url: '../static/aero/aero-hose-bib-2.jpg'},
                    {title: '', url: '../static/aero/aero-hydraulic-1.jpg'},
                    {title: '', url: '../static/aero/aero-hydraulic-2.jpg'},
                    {title: '', url: '../static/aero/aero-hydraulic-3.jpg'},
                    {title: '', url: '../static/aero/aero-race-plane-1.jpg'},
                    {title: '', url: '../static/aero/aero-race-plane-2.jpg'},
                    {title: '', url: '../static/aero/aero-race-plane-3.jpg'},
                    {title: '', url: '../static/aero/aero-race-plane-4.jpg'},
                    {title: '', url: '../static/aero/aero-rectifier-1.jpg'},
                    {title: '', url: '../static/aero/aero-rectifier-2.jpg'},
                    {title: '', url: '../static/aero/aero-stainless-tubes-1.jpg'},
                    {title: '', url: '../static/aero/aero-stainless-tubes-2.jpg'},
                    {title: '', url: '../static/aero/aero-stainless-tubes-3.jpg'},
                    {title: '', url: '../static/aero/aero-tool-die-1.jpg'},
                    {title: '', url: '../static/aero/aero-tool-die-2.jpg'},
                    {title: '', url: '../static/aero/aero-weldment-1.jpg'},
                    {title: '', url: '../static/aero/aero-weldment-2.jpg'},
                    {title: '', url: '../static/aero/aero-weldment-3.jpg'},
                ],
                index: null
            }
        }
    }
</script>

<style scoped>

</style>