import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css'

import { createApp } from 'vue';
import {createRouter, createWebHistory} from 'vue-router'

import LightGallery from 'vue-light-gallery/src/light-gallery';

import App from './App.vue'
import Home from './components/home'
import Contact from './components/contact'
import Capabilities from './components/capabilities'
import Projects from './components/projects'
import Careers from './components/careers'

import Architectural from './components/capabilities/architectural'
import Marine from './components/capabilities/marine'
import Aero from './components/capabilities/aero'
import Machining from './components/capabilities/machining'
import Custom from './components/capabilities/custom'

import RecentProjectsCarousel from './components/recent_project_carousel'

// Vue.use(LightGallery);
// Vue.component('recent-projects-carousel', RecentProjectsCarousel);

const router = new createRouter({
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  base: __dirname,
  routes: [
    { path: '/',  component: Home },
    { path: '/contact',  component: Contact },
    { path: '/capabilities',  component: Capabilities },
    { path: '/projects',  component: Projects },
    { path: '/projects/architectural',  component: Architectural },
    { path: '/projects/marine',  component: Marine },
    { path: '/projects/aero',  component: Aero },
    { path: '/projects/machining',  component: Machining },
    { path: '/projects/custom',  component: Custom },
    { path: '/careers',  component: Careers },
    // { path: '*', redirect: '/' }
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ]
});

// Create the app
// createApp(Vue.util.extend({
//   router
// }, App)).mount('#app');
createApp(App)
  .use(router)
  .component('LightGallery', LightGallery)
  .component('recent-projects-carousel', RecentProjectsCarousel)
  // .use(LightGallery)
  .mount('#app');
