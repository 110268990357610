<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h2>Marine</h2>
                <hr class="small-hr-line">
            </div>
        </div>
        <div class="row">
            <LightGallery
                    :images="images"
                    :index="index"
                    :disable-scroll="true"
                    @close="index = null"
            />

            <div
                    v-for="(image, imageIndex) in images"
                    :key="image.url"
                    @click="index = imageIndex"
            >
                <div class="gallery-item">
                    <img class="gallery-item-img" :src="image.url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "marine_component",
        beforeMount() {
        },
        data() {
            return {
                images: [
                    {title: '', url: '../static/marine/mar-boat-railing-1.jpg'},
                    {title: '', url: '../static/marine/mar-dive-tank-1.jpg'},
                    {title: '', url: '../static/marine/mar-dive-tank-2.jpg'},
                    {title: '', url: '../static/marine/mar-dive-tank-3.jpg'},
                    {title: '', url: '../static/marine/mar-swim-1.jpg'},
                    {title: '', url: '../static/marine/mar-swim-2.jpg'},
                    {title: '', url: '../static/marine/marine-carts-1.jpg'},
                    {title: '', url: '../static/marine/marine-carts-2.jpg'},
                    {title: '', url: '../static/marine/marine-hyd-1.jpg'},
                    {title: '', url: '../static/marine/marine-hyd-2.jpg'},
                    {title: '', url: '../static/marine/marine-milling-1.jpg'},
                    {title: '', url: '../static/marine/marine-milling-2.jpg'},
                    {title: '', url: '../static/marine/marine-supports-1.jpg'},
                    {title: '', url: '../static/marine/marine-supports-2.jpg'},
                    {title: '', url: '../static/marine/marine-towers-1.jpg'},
                    {title: '', url: '../static/marine/marine-towers-2.jpg'},
                    {title: '', url: '../static/marine/tsunami-ball-1.jpg'},
                    {title: '', url: '../static/marine/tsunami-ball-2.jpg'},
                ],
                index: null
            }
        }
    }
</script>

<style scoped>

</style>