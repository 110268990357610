<template>
  <div>
    <div class="header-image">
      <div class="header-overlay">
        <div class="header-title">D&D Welding Fabrication</div>
        <div class="header-description">Locally owned full service welding fabrication company<br>Serving the Pacific Northwest for over 30 years</div>
      </div>
    </div>
    <div class="container">
      <!-- Heading Row -->
      <div class="row align-items-center my-5">
        <!-- /.col-lg-8 -->
          <h1>Full service fabricator where quality is our priority</h1>
          <p class="larger-p">We perform on the standard metals (carbon steel, stainless steel, and aluminum), as well as other exotic metals.</p>
          <p class="larger-p">With more than 30 years of experience we can take any project, small or large, and make it become a reality. Our full service facility makes your project an easy one-stop-shop. We offer services in design, cutting, welding, machining, and we will see your project through to a finish paint and installation.</p>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->

      <div class="align-items-center text-center">
        <router-link class="nav-link" to="/contact">
          <button type="button" class="btn btn-primary">Contact us for your next project</button>
        </router-link>
      </div>
      <hr>
      <!-- Content Row -->
      <div class="row align-items-center">
        <div class="col">
          <h1 class="text-center align-items-center">Recent Projects</h1>
        </div>
      </div>

      <recent-projects-carousel :card-options="{cardHeight: '300px', cardWidth: '300px'}"></recent-projects-carousel>
      <!-- Just here for bottom margin -->
      <div class="row mt-5"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "home"
}
</script>

<style>

  .larger-p {
    font-size: 1.1em;
  }
  .header-image {
    width: 100%;
    height: 250px;
    background-image: url('../assets/welding_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .header-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .header-overlay:after {
    content: "";
    background-color: black;
    opacity: .50;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  .header-title {
    align-content: center;
    text-align: center;
    font-size: 50px;
    color: white;
    font-weight: bolder;
    z-index: 2;
  }
  .header-description {
    align-content: center;
    text-align: center;
    font-size: medium;
    color: whitesmoke;
    font-weight: bold;
    z-index: 2;
  }
</style>