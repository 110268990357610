<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h3>Projects</h3>
                <hr class="small-hr-line">
            </div>
        </div>

        <!-- Industries -->
        <div class="col text-center">
            <h4>Industries</h4>
            <hr class="small-hr-line">
        </div>
        <div class="row">
            <div class="col">
                <router-link to="/projects/architectural">
                    <div class="card" style="width: 20rem;">
                        <div class="card-img-caption">
                            <p class="card-text">Architectural</p>
                            <img class="card-img-top industry-card-img-top" src="../assets/industries_cards/architecture_card.jpg" alt="architectural">
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link to="/projects/marine">
                    <div class="card" style="width: 20rem;">
                        <div class="card-img-caption">
                            <p class="card-text">Marine</p>
                            <img class="card-img-top industry-card-img-top" src="../assets/industries_cards/marine_card.jpg" alt="architectural">
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col">
                <router-link to="/projects/aero">
                    <div class="card" style="width: 20rem;">
                        <div class="card-img-caption">
                            <p class="card-text">Aerospace</p>
                            <img class="card-img-top industry-card-img-top" src="../assets/industries_cards/aerospace_card.jpg" alt="architectural">
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col offset-xl-2 mt-xl-3">
                <router-link to="/projects/machining">
                    <div class="card" style="width: 20rem;">
                        <div class="card-img-caption">
                            <p class="card-text">Machining</p>
                            <img class="card-img-top industry-card-img-top" src="../assets/industries_cards/machineing_card.jpg" alt="architectural">
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col mt-xl-3">
                <router-link to="/projects/custom">
                    <div class="card" style="width: 20rem;">
                        <div class="card-img-caption">
                            <p class="card-text">Custom</p>
                            <img class="card-img-top industry-card-img-top" src="../assets/industries_cards/custom_card.jpg" alt="architectural">
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <!-- Recent Projects -->
        <div class="row mt-5">
            <div class="col text-center">
                <h4>Recent</h4>
                <hr class="small-hr-line">
            </div>
        </div>
        <recent-projects-carousel :card-options="{cardHeight: '250px', cardWidth: '250px'}"></recent-projects-carousel>
        <!-- Just here for bottom margin -->
        <div class="row mt-5"></div>
    </div>

</template>

<script>
    export default {
        name: "projects"
    }
</script>

<style scoped>

</style>