<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col text-center">
                <h2>Architectural</h2>
                <hr class="small-hr-line">
            </div>
        </div>
        <div class="row">
            <LightGallery
                :images="images"
                :index="index"
                :disable-scroll="true"
                @close="index = null"
            />

            <div
                v-for="(image, imageIndex) in images"
                :key="image.url"
                @click="index = imageIndex"
            >
                <div class="gallery-item">
                    <img class="gallery-item-img" :src="image.url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "architectural_component",
        beforeMount() {
        },
        data() {
            return {
                images: [
                    {title: '', url: '../static/arch/stairwell.jpg'},
                    {title: '', url: '../static/arch/stairwell_2.jpg'},
                    {title: '', url: '../static/arch/arch-allumdeck-1.jpg'},
                    {title: '', url: '../static/arch/arch-allumdeck-2.jpg'},
                    {title: '', url: '../static/arch/arch-allumdeck-3.jpg'},
                    {title: '', url: '../static/arch/arch-aluminumglass-2.jpg'},
                    {title: '', url: '../static/arch/arch-cov-1.jpg'},
                    {title: '', url: '../static/arch/arch-cov-2.jpg'},
                    {title: '', url: '../static/arch/arch-cov-3.jpg'},
                    {title: '', url: '../static/arch/arch-cov-4.jpg'},
                    {title: '', url: '../static/arch/arch-cov-5.jpg'},
                    {title: '', url: '../static/arch/arch-cov-6.jpg'},
                    {title: '', url: '../static/arch/arch-ext-1.jpg'},
                    {title: '', url: '../static/arch/arch-ext-2.jpg'},
                    {title: '', url: '../static/arch/arch-ext-3.jpg'},
                    {title: '', url: '../static/arch/arch-ext-4.jpg'},
                    {title: '', url: '../static/arch/arch-int-1.jpg'},
                    {title: '', url: '../static/arch/arch-int-2.jpg'},
                    {title: '', url: '../static/arch/arch-int-3.jpg'},
                    {title: '', url: '../static/arch/arch-int-4.jpg'},
                    {title: '', url: '../static/arch/arch-int-5.jpg'},
                    {title: '', url: '../static/arch/arch-int-6.jpg'},
                    {title: '', url: '../static/arch/arch-int-7.jpg'},
                    {title: '', url: '../static/arch/arch-powder-1.jpg'},
                    {title: '', url: '../static/arch/arch-railing-1.jpg'},
                    {title: '', url: '../static/arch/arch-railing-2.jpg'},
                    {title: '', url: '../static/arch/arch-railing-3.jpg'},
                    {title: '', url: '../static/arch/arch-railing-4.jpg'},
                    {title: '', url: '../static/arch/arch-rooftop-1.jpg'},
                    {title: '', url: '../static/arch/arch-rooftop-2.jpg'},
                    {title: '', url: '../static/arch/arch-star-rail-1.jpg'},
                    {title: '', url: '../static/arch/arch-steelposts-1.jpg'},
                    {title: '', url: '../static/arch/arch-steelposts-2.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-1.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-2.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-3.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-4.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-5.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-6.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-7.jpg'},
                    {title: '', url: '../static/arch/arch-sunroof-8.jpg'},
                    {title: '', url: '../static/arch/arch-ymca-1.jpg'},
                    {title: '', url: '../static/arch/arch-ymca-2.jpg'},
                    {title: '', url: '../static/arch/arch-ymca-3.jpg'},
                    {title: '', url: '../static/arch/arch-ymca-4.jpg'},
                    {title: '', url: '../static/arch/arch-ymca-5.jpg'},
                ],
                index: null
            }
        }
    }
</script>

<style scoped>

</style>